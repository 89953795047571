export var config = {
    apiKey: "AIzaSyBbHavFcbUmcYJ-rRqpcNVGUNnMl-maHAM",
    authDomain: "bethadamsartist.firebaseapp.com",
    databaseURL: "https://bethadamsartist.firebaseio.com",
    projectId: "bethadamsartist",
    storageBucket: "bethadamsartist.appspot.com",
    messagingSenderId: "368310861342",
    appId: "1:368310861342:web:517db863ef3f62e977f80f",
    measurementId: "G-MEMTH2JZ0X"
  };

