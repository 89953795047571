import { Component, OnInit } from '@angular/core';
import * as jquery from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var imagePath = './assets/img/slides/badams-';
    var bg = jquery("div.bg");
    var imgNumber = 2;
    var controlSpeed = 300;
    var changing = false;
    var numberOfImages = 19;

    function isChanging() {
      changing = !changing
    }

    function slidePreLoad() {
      var preLoadArray = [];
      for(var i = 1; i <= numberOfImages; i++) {
        var preLoadEle = "<img style='display: none; visibility: hidden' src='./assets/img/slides/badams-" + i + "-min.jpg'>"
        preLoadArray.push(preLoadEle);
      }
      var preLoadStr = preLoadArray.join('');
      jquery("body").append(preLoadStr);

    }

    slidePreLoad();

    jquery("div.ctrl-left").on("click", function () {
      if(!changing) {
        clearInterval(interval);
        changing = true;
        if(imgNumber === 1) {
          imgNumber = numberOfImages;
        } else {
          imgNumber--;
        }
        if(jquery(bg[0]).hasClass("active")) {
          jquery(bg[1]).css("background-image", "url(" + imagePath + imgNumber + "-min.jpg" +")")
          jquery(bg[0]).fadeOut(controlSpeed).removeClass("active");
          setTimeout(function() {
            isChanging();
          }, controlSpeed)
        } else {
          setTimeout(function() {
            isChanging();
          }, controlSpeed)
          jquery(bg[0]).css("background-image", "url(" + imagePath + imgNumber + "-min.jpg" +")").fadeIn(controlSpeed).addClass("active")
          jquery(bg[1]).removeClass("active");
        }
      }
    })

    jquery("div.ctrl-right").on("click", function () {
      if(!changing) {
        clearInterval(interval);
        changing = true;
        if(imgNumber === numberOfImages) {
          imgNumber = 1;
        } else {
          imgNumber++;
        }
        if(jquery(bg[0]).hasClass("active")) {
          jquery(bg[1]).css("background-image", "url(" + imagePath + imgNumber + "-min.jpg" +")")
          jquery(bg[0]).fadeOut(controlSpeed).removeClass("active");
          setTimeout(function() {
            isChanging();
          }, controlSpeed)
        } else {
          setTimeout(function() {
            isChanging();
          }, controlSpeed)
          jquery(bg[0]).css("background-image", "url(" + imagePath + imgNumber + "-min.jpg" +")").fadeIn(controlSpeed).addClass("active")
          jquery(bg[1]).removeClass("active");
        }
      }
    })

    var interval = setInterval(function() {
      if (!changing) {
        changing = true;
        if (imgNumber === numberOfImages) {
          imgNumber = 1;
        }
        if(jquery(bg[0]).hasClass("active")) {
          jquery(bg[1]).css("background-image", "url(" + imagePath + imgNumber + "-min.jpg" +")")
          jquery(bg[0]).fadeOut(2000).removeClass("active");
          setTimeout(function() {
            isChanging();
          }, 2000)
        } else {
          setTimeout(function() {
            isChanging();
          }, 2000)
          jquery(bg[0]).css("background-image", "url(" + imagePath + imgNumber + "-min.jpg" +")").fadeIn(2000).addClass("active")
          jquery(bg[1]).removeClass("active");
        }
        imgNumber++;
      }



    }, 7000)

  }

}
